import { CloseOutlined } from '@ant-design/icons'
import cookie from 'assets/cookies.svg'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AnimateCenter from './Animations/AnimationCenter'

export default function CookieModal() {
	const { t } = useTranslation('home')

	const [isCookieConsentDialogOpen, setIsCookieConsentDialogOpen] = useState(true)

	useEffect(() => {
		const consent = localStorage.getItem('cookieConsent')

		if (consent === 'true') {
			setIsCookieConsentDialogOpen(false)
		}
	}, [])

	return (
		<>
			<AnimatePresence>
				{isCookieConsentDialogOpen ? (
					<AnimateCenter
						exit={{ opacity: 0 }}
						className='fixed left-8 max-md:left-[2.5%] right-0 flex flex-col gap-3 rounded-t-[8px] border border-border-[#F1F5F9] !bg-gray-50 z-[7000] p-4 pb-6 bottom-6 max-md:bottom-2 md:left-[2.5%] w-[95%] max-md:max-w-[474px] lg:rounded-[8px] lg:pb-4'
					>
						<div className='w-full flex items-center justify-between gap-4 max-md:flex-col max-md:gap-6 max-md:items-start'>
							<div className='flex items-center gap-4 max-md:flex-col max-md:items-start'>
								<div className='flex items-center justify-start gap-2.5'>
									<img src={cookie} alt='cookie icon' className='origin-center w-12 h-12 max-md:w-8 max-md:h-8' />
									<p className='md:hidden flex text-base leading-6 font-[700] text-[#170F49] font-bold max-w-[500px]'>
										{t('cookieModal.title')}
									</p>
								</div>

								<p className='text-sm leading-6 text-[#667085] font-[Poppins] max-w-[500px] max-md:max-w-[auto]'>
									{t('cookieModal.text')}{' '}
									<Link to='/privacy-policy' className='font-[700] text-[#170F49]'>
										{t('cookieModal.privacy')}
									</Link>{' '}
									{t('cookieModal.and')}{' '}
									<Link to='/cookie-notice' className='font-[700] text-[#170F49]'>
										{t('cookieModal.cookie')}
									</Link>
									.
								</p>
							</div>

							<div className='flex items-center justify-end gap-2 max-lg:flex-wrap font-[PoppinsSemibold] max-md:justify-start'>
								<button
									className='rounded-[5px] bg-[#1B2761] px-6 py-2.5 text-sm text-white transition hover:brightness-90 active:brightness-90 h-full'
									onClick={() => {
										localStorage.setItem('cookieConsent', 'true')
										setIsCookieConsentDialogOpen(false)
									}}
								>
									{t('cookieModal.accept')}
								</button>

								<button
									className='rounded-[8px] border border-[#E8E9EF] bg-transparent px-6 py-2.5 text-sm font-medium text-[#0C111D] transition hover:brightness-90 active:brightness-90 h-full capitalize'
									onClick={() => {
										localStorage.setItem('cookieConsent', 'false')
										setIsCookieConsentDialogOpen(false)
									}}
								>
									{t('cookieModal.decline')}
								</button>
							</div>
							<CloseOutlined
								className='max-md:absolute max-md:right-4 text-[#170F49] text-xl cursor-pointer'
								onClick={() => {
									setIsCookieConsentDialogOpen(false)
								}}
							/>
						</div>
					</AnimateCenter>
				) : null}
			</AnimatePresence>
		</>
	)
}
