import { ReactComponent as LineWhiteIcon } from 'assets/line-white.svg'
import SCAcademy from 'assets/sc-academy.svg'
import SCAdhere from 'assets/sc-adhere-new.svg'
import SCIntel from 'assets/sc-intel.svg'
import SCSecure from 'assets/sc-secure.svg'
import SCTrust from 'assets/sc-trust.svg'
import smart_comply_logo_blue from 'assets/smartcomply-blue.svg'
import { useGetStartedContext } from 'context/GetStartedContextProvider'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import MobileNavbar from './MobileNavbar'
import LanguageSwitcher from './sections/home/LanguageSwitcher'

const Navbar = () => {
	const pathname = useLocation().pathname
	const { t } = useTranslation('home')

	const { handleModalChange } = useGetStartedContext()

	const scrollTargetRef = useRef(null)

	const handleHover = () => {
		if (scrollTargetRef.current) {
			scrollTargetRef.current.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}
	}

	return (
		<nav
			className={`z-[1000] fixed w-full top-0 h-[85px] max-md:h-[70px] flex flex-row items-center justify-between py-4 gap-6 px-14 max-md:p-8 max-[520px]:px-4 max-md:py-6 ${
				pathname === '/' ? '' : ''
			} font-[Manrope] bg-[#E5F0FF] border border-[#ECECEC]`}
		>
			<div className='flex gap-4 items-center justify-start'>
				<Link to='/'>
					<img src={smart_comply_logo_blue} alt='Smartcomply' className='w-32 max-md:w-28 max-[520px]:w-24' />
				</Link>
				<LanguageSwitcher />
			</div>

			<div className='hidden md:flex flex-row items-center gap-10 max-lg:gap-5 text-black text-[14px]'>
				<Link to='/' className='capitalize'>
					{t('navlinks.home')}
				</Link>

				<div className='relative z-40'>
					<div className='group inline-block'>
						<button onMouseEnter={handleHover} className='outline-none focus:outline-none flex items-center'>
							<span className='capitalize'>{t('navlinks.products.title')}</span>
							<span className=''>
								<svg
									className='fill-current h-5 w-5 transform group-hover:-rotate-180 transition duration-150 ease-in-out'
									xmlns='http://www.w3.org/2000/svg'
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'
								>
									<path
										d='M6.91895 10.3371L9.69134 13.196C9.86896 13.3792 10.1311 13.3792 10.3087 13.196L15.6668 7.67064C16.0011 7.32586 15.7984 6.66675 15.3581 6.66675H10.5893L6.91895 10.3371Z'
										fill='black'
									/>
									<path
										opacity='0.5'
										d='M9.41083 6.66675H4.642C4.20165 6.66675 3.99899 7.32586 4.33333 7.67064L6.33882 9.73876L9.41083 6.66675Z'
										fill='black'
									/>
								</svg>
							</span>
						</button>
						<div className=''>
							<ul className='pt-4 transform scale-0 group-hover:scale-100 absolute left-0 transition duration-150 space-y-2 ease-in-out origin-top z-40'>
								<div
									ref={scrollTargetRef}
									className='rounded-lg  max-h-[400px] overflow-y-auto w-[340px] p-8 space-y-3 bg-white relative border border-b-[#E8E9EF] shadow-[0px_4px_55px_5px_#12121226]'
								>
									<div className='p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg'>
										<Link target='_blank' to='https://secure.smartcomply.com' className='flex flex-row items-start justify-start space-x-3'>
											<img src={SCSecure} className='w-7 h-7 flex-shrink-0' alt='secure' eager />
											<div>
												<h4 className='font-[Manrope] font-[600] text-[15px] text-black mb-2'>Smartcomply Secure</h4>
												<p className='font-[Manrope] text-[#6F6F6F] text-[14px]'>{t('navlinks.products.secure')}</p>
											</div>
										</Link>
									</div>
									<div className='p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg'>
										<Link
											target='_blank'
											to='https://adhere.smartcomply.com/'
											className='flex flex-row items-start justify-start space-x-3'
										>
											<img src={SCAdhere} className='w-7 h-7 flex-shrink-0' alt='adhere' eager />
											<div>
												<h4 className='font-[Manrope] font-[600] text-[15px] text-black mb-2'>Adhere by Smartcomply</h4>
												<p className='font-[Manrope] text-[#6F6F6F] text-[14px]'>{t('navlinks.products.adhere')}</p>
											</div>
										</Link>
									</div>
									<div className='p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg'>
										<Link target='_blank' to='https://intel.smartcomply.com/' className='flex flex-row items-start justify-start space-x-3'>
											<img src={SCIntel} className='w-7 h-7 flex-shrink-0' alt='intel' eager />
											<div>
												<h4 className='font-[Manrope] font-[600] text-[15px] text-black mb-2'>SmartcomplyIntel</h4>
												<p className='font-[Manrope] text-[#6F6F6F] text-[14px]'>{t('navlinks.products.intel')}</p>
											</div>
										</Link>
									</div>
									<div className='p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg'>
										<Link
											target='_blank'
											to='https://academy.smartcomply.com/'
											className='flex flex-row items-start justify-start space-x-3'
										>
											<img src={SCAcademy} className='w-7 h-7 flex-shrink-0' alt='academy' eager />{' '}
											<div>
												<h4 className='font-[Manrope] font-[600] text-[15px] text-black mb-2'>Smartcomply Academy</h4>
												<p className='font-[Manrope] text-[#6F6F6F] text-[14px]'>{t('navlinks.products.academy')}</p>
											</div>
										</Link>
									</div>

									<div className='p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg'>
										<p className='flex flex-row items-start justify-start space-x-3'>
											<img src={SCTrust} className='w-7 h-7 flex-shrink-0' alt='trust' eager />{' '}
											<div>
												<h4 className='font-[Manrope] font-[600] text-[15px] text-black mb-2'>Smartcomply Trust</h4>
												<p className='font-[ManropeMedium] text-[#EF5025] text-[14px] uppercase'>{t('navlinks.comingSoon')}!!!</p>
											</div>
										</p>
									</div>
								</div>
							</ul>
						</div>
					</div>
				</div>

				<Link to='/about-us' className='capitalize'>
					{t('navlinks.about')}
				</Link>
				<Link to='/blog' className='capitalize'>
					{t('blog.title')}
				</Link>
				<div
					className='cursor-pointer flex text-[14px] text-[#ffffff]  font-[PoppinsMedium] items-center justify-center mx-auto px-6 py-4 w-fit gap-2 rounded-sm bg-[#1B2761]'
					onClick={() => handleModalChange(true)}
				>
					<p className='capitalize'>{t('button.getStarted')}</p>
					<LineWhiteIcon />
					<p className='capitalize'>{t('button.now')}</p>
				</div>
			</div>

			<div className='block md:hidden'>
				<MobileNavbar pathname={pathname} />
			</div>
		</nav>
	)
}

export default Navbar
