import { useGSAP } from '@gsap/react'
import academy_cert from 'assets/academy-cert.png'
import adhere from 'assets/adhere.png'
import defense from 'assets/defense.png'
import intel from 'assets/intel.png'
import { ReactComponent as Line } from 'assets/line-small.svg'
import SCAcademy from 'assets/sc-academy.svg'
import SCAdhere from 'assets/sc-adhere.svg'
import SCIntel from 'assets/sc-intel.svg'
import SCSecure from 'assets/sc-secure.svg'
import SCTrust from 'assets/sc-trust.svg'
import shield from 'assets/shield2.png'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

gsap.registerPlugin(ScrollTrigger)

const ProductsAnimation = () => {
	const { t } = useTranslation('home')

	const containerRef = useRef()

	const productsRef = useRef([]) // stores multiple refs

	useGSAP(() => {
		// converts the current array of image references to a proper array for GSAP manipulation
		const products = gsap.utils.toArray(productsRef.current)

		// iterate over each product element
		products.forEach((panel, i) => {
			let scale = 1

			// if current image is not the last one, adjust the scale based on its index
			if (i !== products.length - 1) {
				scale = 0.9 + 0.025 * i // create a slight scaling effect for products based on their index
			}

			gsap.fromTo(
				panel,
				{},
				{
					scale: scale,
					opacity: 1,
					transformOrigin: 'top center', // point from which the scaling transformation occurs
					ease: 'none',

					scrollTrigger: {
						trigger: panel, // set current item as the trigger for the ScrollTrigger

						// define when the animation should start based on the position of the trigger
						start: 'top ' + (70 + 40 * i), // start the animation when the top of the panel is 70px down plus an offset based on index
						end: 'bottom +=650px', // define when the animation should end (bottom of the panel + 650px)
						// end: "bottom center",
						endTrigger: '.end', // end trigger element
						pin: true, // pin the current panel in place while it is being triggered
						pinSpacing: false, // disable additional spacing around pinned elements
						scrub: true, // for smooth animation with scrolling
						onEnter: a => {
							// console.log(panel, 'entered', a)
							panel.style.opacity = 1
						},
						onLeave: () => {},
						onEnterBack: () => {},
						onLeaveBack: () => {}
						// markers: true
					}
				}
			)
		})
	}, [])

	return (
		<>
			<div className='px-14 max-[1200px]:px-0 bg-white'>
				<div className='flex flex-col' ref={containerRef}>
					<div id='1' className={`w-full h-[26rem]`} style={{ backgroundColor: 'tranparent' }} ref={el => (productsRef.current[0] = el)}>
						<div className='w-full h-[26rem] overflow-hidden items-start flex max-md:flex-col max-md:gap-0 gap-6 max-lg:gap-3 justify-center'>
							<div className='w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(196.92deg,#FFE5F7_11.66%,#FFFFFF_84.18%)]'>
								<img src={SCSecure} className='mb-6' alt='secure' eager />

								<h4 className='text-[#0B1029] text-lg font-[Manrope] font-[600]'>{t('products.secure.name')}</h4>
								<p className='text-[#424242] font-[Manrope] text-sm'>{t('products.secure.subtitle')}</p>
								<div className='flex gap-3 text-sm items-center justify-start pt-6'>
									<Link className='text-[#1B2761] text-[15px] font-[PoppinsSemibold]' to='https://secure.smartcomply.com/' target='_blank'>
										{t('learnMore')}{' '}
									</Link>
									<Line className='' />
								</div>
							</div>
							<div className='flex-1 h-full'>
								<img
									className='h-full w-full object-cover object-top rounded-2xl max-md:w-full max-md:max-h-[400px]'
									src={defense}
									alt='smartcomply secure'
								/>
							</div>
						</div>
					</div>

					<div id='2' className={` w-full h-[26rem]`} style={{ backgroundColor: 'tranparent' }} ref={el => (productsRef.current[1] = el)}>
						<div className='w-full h-[26rem] overflow-hidden items-start flex max-md:flex-col max-md:gap-0 gap-6 max-lg:gap-3 justify-center'>
							<div className='w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(8.3deg,#FFFFFF_9.89%,#E5F0FF_96.6%)]'>
								<img src={SCAdhere} className='mb-6' alt='adhere' eager />
								<h4 className='text-[#0B1029] text-lg font-[Manrope] font-[600]'>{t('products.adhere.name')}</h4>
								<p className='text-[#424242] font-[Manrope] text-sm'>{t('products.adhere.subtitle')}</p>
								<div className='flex gap-3 text-sm items-center justify-start pt-6'>
									<Link className='text-[#1B2761] text-[15px] font-[PoppinsSemibold]' to='https://adhere.smartcomply.com/' target='_blank'>
										{t('learnMore')}{' '}
									</Link>
									<Line className='' />
								</div>
							</div>
							<div className='flex-1 h-full'>
								<img
									className='h-full w-full object-cover object-top rounded-2xl max-md:w-full max-md:max-h-[400px]'
									src={adhere}
									alt='adhere by smartcomply'
								/>
							</div>
						</div>
					</div>

					<div className={`w-full h-[26rem]`} style={{ backgroundColor: 'tranparent' }} ref={el => (productsRef.current[2] = el)}>
						<div className='w-full h-[26rem] overflow-hidden items-start flex max-md:flex-col max-md:gap-0 gap-6 max-lg:gap-3 justify-center'>
							<div className='w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(25.89deg,#FFFFFF_27.48%,#F1FFD6_83.66%)]'>
								<img src={SCIntel} className='mb-6' alt='intel' eager />
								<h4 className='text-[#0B1029] text-lg font-[Manrope] font-[600]'>{t('products.intel.name')}</h4>
								<p className='text-[#424242] font-[Manrope] text-sm'>{t('products.intel.subtitle')}</p>
								<div className='flex gap-3 text-sm items-center justify-start pt-6'>
									<Link className='text-[#1B2761] text-[15px] font-[PoppinsSemibold]' to='https://intel.smartcomply.com/' target='_blank'>
										{t('learnMore')}{' '}
									</Link>
									<Line className='' />
								</div>
							</div>
							<div className='flex-1 h-full'>
								<img
									className='h-full w-full object-cover object-top rounded-2xl max-md:w-full max-md:max-h-[400px]'
									src={intel}
									alt='smartcomplyintel'
								/>
							</div>
						</div>
					</div>
					<div className={`w-full h-[26rem]`} style={{ backgroundColor: 'tranparent' }} ref={el => (productsRef.current[3] = el)}>
						<div className='w-full h-[26rem] overflow-hidden items-start flex max-md:flex-col max-md:gap-0 gap-6 max-lg:gap-3 justify-center'>
							<div className='w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(0.24deg,#FFFFFF_0.21%,#E8E9EF_99.79%)]'>
								<img src={SCAcademy} className='mb-6' alt='academy' eager />
								<h4 className='text-[#0B1029] text-lg font-[Manrope] font-[600]'>{t('products.academy.name')}</h4>
								<p className='text-[#424242] font-[Manrope] text-sm'>{t('products.academy.subtitle')}</p>
								<div className='flex gap-3 text-sm items-center justify-start pt-6'>
									<Link className='text-[#1B2761] text-[15px] font-[PoppinsSemibold]' to='https://academy.smartcomply.com/' target='_blank'>
										{t('learnMore')}{' '}
									</Link>
									<Line className='' />
								</div>
							</div>
							<div className='flex-1 h-full'>
								<img
									className='h-full w-full object-cover object-top rounded-2xl max-md:w-full max-md:max-h-[400px]'
									src={academy_cert}
									alt='smartcomply academy'
								/>
							</div>
						</div>
					</div>
					<div className={`list-style-none w-full h-[26rem]`} style={{ backgroundColor: 'tranparent' }} ref={el => (productsRef.current[4] = el)}>
						<div className='w-full h-[26rem] overflow-hidden items-start flex max-md:flex-col max-md:gap-0 gap-6 max-lg:gap-3 justify-center'>
							<div className='w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(0deg,#FFFFFF_9.4%,#E3FDF9_100%)]'>
								<img src={SCTrust} className='mb-6' alt='trust' eager />
								<h4 className='text-[#0B1029] text-lg font-[Manrope] font-[600]'>{t('products.trust.name')}</h4>
								<p className='text-[#424242] font-[Manrope] text-sm'>{t('products.trust.subtitle')}</p>
								<div className='flex gap-3 text-sm items-center justify-start pt-6'>
									<p className='text-[#1B2761] text-[15px] font-[PoppinsSemibold] uppercase'>{t('navlinks.comingSoon')}!!!</p>
									{/* <Line className="" /> */}
								</div>
							</div>
							<div className='flex-1 h-full'>
								<img
									className='h-full w-full object-cover object-top rounded-2xl max-md:w-full max-md:max-h-[400px]'
									src={shield}
									alt='smartcomply trust'
								/>
							</div>
						</div>
					</div>
					<div className='end'></div>
				</div>
			</div>
		</>
	)
}

export default ProductsAnimation
