import Particles, { initParticlesEngine } from '@tsparticles/react'
import { loadSlim } from '@tsparticles/slim'
import { ReactComponent as LineWhiteIcon } from 'assets/line-white.svg'
import { ReactComponent as PlayIcon } from 'assets/play.svg'
import AnimateBottom from 'components/Animations/AnimateBottom'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

// logos
import abeg from 'assets/new-logos/abeg-white.png'
import brbcapital from 'assets/new-logos/brb-capital.png'
import lenco from 'assets/new-logos/lenco-white.png'
import nova from 'assets/new-logos/nova-bank-white.png'
import piggyvest from 'assets/new-logos/piggyvest-white.png'
import pivo from 'assets/new-logos/pivo-white.png'
import redtech from 'assets/new-logos/redtech-white.png'
import suntrust from 'assets/new-logos/suntrust-bank.png'
import tellerone from 'assets/new-logos/tellerone-white.png'
import unitybank from 'assets/new-logos/unity-bank.png'
import { useGetStartedContext } from 'context/GetStartedContextProvider'
import { isFirefox } from 'react-device-detect'

const HeroSection = () => {
	const [init, setInit] = useState(false)
	const { t } = useTranslation('home')
	const { handleModalChange } = useGetStartedContext()

	// this should be run only once per application lifetime
	useEffect(() => {
		if (!isFirefox) {
			initParticlesEngine(async engine => {
				// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
				// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
				await loadSlim(engine)
			}).then(() => {
				setInit(true)
			})
		}
	}, [isFirefox])

	const particlesLoaded = () => {
		console.log('particles loaded')
	}

	const options = useMemo(
		() => ({
			background: {},
			fpsLimit: 120,
			interactivity: {
				events: {
					onClick: {
						enable: true,
						mode: 'push'
					},
					onHover: {
						enable: true,
						mode: 'repulse'
					}
				},
				modes: {
					push: {
						quantity: 4
					},
					repulse: {
						distance: 200,
						duration: 0.4
					}
				}
			},
			particles: {
				color: {
					value: '#0052B4'
				},
				links: {
					color: '#0052B4',
					distance: 150,
					enable: true,
					opacity: 0.4,
					width: 1
				},
				move: {
					direction: 'none',
					enable: true,
					outModes: {
						default: 'bounce'
					},
					random: false,
					straight: false,
					speed: 2,
					out_mode: 'out',
					bounce: false,
					attract: {
						enable: false,
						rotateX: 600,
						rotateY: 1200
					}
				},
				number: {
					density: {
						enable: true,
						value_area: 50
					},
					value: 380
				},
				opacity: {
					value: 0.5
				},
				shape: {
					type: 'circle',
					stroke: {
						width: 0,
						color: '#000000'
					},
					polygon: {
						nb_sides: 5
					}
				},
				size: {
					value: { min: 1, max: 4 },
					anim: {
						enable: false,
						speed: 40,
						size_min: 0.1,
						sync: true,
						random: true
					}
				}
			},
			detectRetina: true,
			fullScreen: false,
			poisson: {
				enable: true
			}
		}),
		[]
	)

	return (
		<>
			<div className='relative'>
				<div className='absolute top-0 h-full w-full hero-glaze' />
				<div className='absolute left-[25%] bottom-0 h-56 w-14 bg-[#0052B4] blur-[80px] rotate-45' />
				<div className='absolute left-[50%] bottom-0 h-56 w-14 bg-[#0052B4] blur-[80px] rotate-45' />

				{init && !isFirefox ? <Particles options={options} id='tsparticles' particlesLoaded={particlesLoaded} height={400} fullScreen={false} /> : null}
				<div id='hero-section' className='relative px-14 pt-14 pb-4 mt-[85px] max-md:mt-[70px] max-md:px-8 max-[520px]:px-4 max-lg:pt-10 relative'>
					<div className='flex flex-col space-y-8 md:space-y-0 md:flex-row md:justify-between md:space-x-10 py-4 gap-4'>
						<div className='w-full space-y-8 md:space-y-6'>
							<div>
								<h2 className='font-bold text-[45px] max-lg:text-[40px] max-sm:text-[33px] leading-tight text-[#0B1029] max-md:text-center max-md:mx-auto'>
									{t('header.title1')}
								</h2>
								<h2 className='font-bold max-w-[350px] text-[45px] max-lg:text-[40px] max-sm:text-[33px] leading-tight text-[#0B1029] max-md:text-center max-md:mx-auto'>
									{t('header.title2')}
								</h2>
							</div>

							<p className='text-black font-[Manrope] md:w-[48%] max-md:text-center max-md:mx-auto'>{t('header.subtitle')}</p>
							<div className='flex justify-start items-center max-md:flex-wrap max-md:justify-center'>
								<div
									onClick={() => {
										handleModalChange(true)
									}}
									className='cursor-pointer flex h-fit w-max items-center text-[14px] text-[#ffffff] font-[PoppinsMedium] justify-center px-6 py-4 w-fit gap-2 rounded-md bg-[#1B2761]'
								>
									<p className=''>{t('button.demo')}</p>
									<LineWhiteIcon className='flex-shrink-0' />
									<p className=''>{t('button.now')}</p>
								</div>
								<Link
									to='https://youtu.be/zjsAvAInW9Q?si=XHcznbIUBvLR1Yqy'
									target='_blank'
									className='flex h-fit items-center text-[14px] text-[#0C111D] font-[PoppinsMedium] justify-center px-6 py-4 w-fit gap-0.5 font-[Manrope] rounded-md bg-transparent'
								>
									<PlayIcon className='flex-shrink-0' />
									<p className='w-max'>{t('button.knowUs')}</p>
								</Link>
							</div>

							{/* <div className="flex justify-start items-center max-md:justify-center gap-4 max-sm:flex-wrap">
                <p className="font-[Manrope] text-[#666D80] text-md">
                  {t("header.compliant")}
                </p>
                <LineBlackIcon className="hidden md:inline-flex flex-shrink-0" />
                <div className="flex justify-start items-center gap-3 max-sm:flex-wrap">
                  <NDPR className="" />
                  <PCIDSS className="" />
                  <SOC className="" />
                  <ISO className="" />
                </div>
              </div> */}
						</div>
					</div>
				</div>
			</div>

			<div className='px-14 pb-14 max-md:px-0 '>
				<AnimateBottom delay={0.4}>
					<div className='bg-[#0B1029] overflow-hidden w-full rounded-none md:rounded-lg flex flex-col justify-center items-start px-12 py-12 max-md:px-8'>
						<p className='uppercase text-left text-sm text-[#979FC9] tracking-[0.1em] mb-8 max-md:mb-5 max-md:text-center'>
							{t('header.trustedText')}
						</p>
						<div className='w-full flex gap-8 items-center justify-between logos'>
							<img className='max-w-[70px]' src={redtech} alt='redtech' />

							<img className='max-w-[70px]' src={abeg} alt='abeg' />

							<img className='max-w-[70px]' src={unitybank} alt='unitybank' />

							<img className='max-w-[70px]' src={tellerone} alt='tellerone' />

							<img className='max-w-[70px]' src={nova} alt='nova' />

							<img className='max-w-[70px]' src={piggyvest} alt='piggyvest' />

							<img className='max-w-[70px]' src={suntrust} alt='subtrust bank' />

							<img className='max-w-[70px]' src={pivo} alt='pivo' />

							<img className='max-w-[70px]' src={brbcapital} alt='brb capital' />

							<img className='max-w-[70px]' src={lenco} alt='lenco' />
						</div>
					</div>
				</AnimateBottom>
			</div>
		</>
	)
}
export default HeroSection
