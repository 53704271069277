import academy from 'assets/academy-group.svg'
import academy_mobile from 'assets/academy-mobile.svg'
import adhere from 'assets/adhere-group.svg'
import adhere_mobile from 'assets/adhere-mobile.svg'
import intel from 'assets/intel-group.svg'
import intel_mobile from 'assets/intel-mobile.svg'
import secure_mobile from 'assets/secure-group-mobile.svg'
import secure from 'assets/secure-group.svg'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

const Tools = () => {
	const { t } = useTranslation('home')

	const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

	const [activeTab, setActiveTab] = useState(0)
	const tabs = [
		{
			name: 'Smartcomply Secure',
			key: 'secure',
			image: secure,
			mobile_image: secure_mobile,
			text: 'Check your cloud environment regularly for potential weaknesses.'
		},
		{
			name: 'Adhere by Smartcomply',
			key: 'adhere',
			image: adhere,
			mobile_image: adhere_mobile,
			text: 'Check your cloud environment regularly for potential weaknesses.'
		},
		{
			name: 'SmartcomplyIntel',
			key: 'intel',
			image: intel,
			mobile_image: intel_mobile,
			text: 'Check your cloud environment regularly for potential weaknesses.'
		},
		{
			name: 'Smartcomply Academy',
			key: 'academy',
			image: academy,
			mobile_image: academy_mobile,
			text: 'Check your cloud environment regularly for potential weaknesses.'
		}
	]

	const tabPanelsRef = useRef([])

	useEffect(() => {
		const interval = setInterval(() => {
			setActiveTab(prevTab => (prevTab + 1) % tabs.length) // Cycle through tabs
		}, 6000)

		return () => clearInterval(interval) // Clean up interval on component unmount
	}, [tabs.length])

	useEffect(() => {
		if (tabPanelsRef.current[activeTab]) {
			tabPanelsRef.current[activeTab].scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			})
		}
	}, [activeTab])

	return (
		<>
			<section className=' px-14 py-24 max-md:px-8 max-[520px]:px-4 max-lg:py-14 max-md:py-10 bg-[linear-gradient(8.3deg,#FFFFFF_9.89%,#E5F0FF_96.6%)]'>
				<div className='mb-14 max-md:mb-6 flex items-center justify-center flex-col mx-auto'>
					<h3 className='md:inline-flex hidden text-[#0D0D12] font-bold lg:text-5xl md:text-4xl md:max-w-[80%] lg:max-w-[70%] text-center mb-4'>
						{t('section2.header')}{' '}
					</h3>
					<h3 className='md:hidden text-[#0D0D12] leading-tight font-bold text-[28px] w-[80%] max-[520px]:w-full text-center mb-4'>
						{t('section2.header2')}{' '}
					</h3>
					<h3 className='md:hidden text-[#666D80] font-[Manrope] max-w-[240px] text-base text-center mb-4'>{t('section2.header')} </h3>
				</div>

				<section className='flex flex-col justify-center items-center gap-12 max-md:gap-6'>
					<div className='no-scrollbar w-full overflow-y-auto whitespace-nowrap mx-auto text-center justify-center items-center gap-2 text-[15px]'>
						{tabs?.map((tab, key) => (
							<div
								key={tab.key}
								className={`${
									tabs[activeTab]?.key === tab?.key
										? 'text-[#1B2761] font-[PoppinsSemibold] border-b-2 border-[#1B2761]'
										: 'text-[#667085] font-[Poppins] cursor-pointer'
								} px-3 py-4 max-md:pb-2 inline-flex`}
								onClick={() => setActiveTab(key)}
							>
								{tab?.name}
							</div>
						))}
					</div>
					<div className='tab-panels max-md:min-h-[280px] md:h-[44.4vw] overflow-y-hidden scroll-smooth'>
						{tabs.map((tab, index) => (
							<div
								key={'panel' + index}
								// ref={(el) => (tabPanelsRef.current[index] = el)} // Set the ref for each tab panel
								className={`fade ${activeTab === index ? 'block' : 'hidden'}`}
							>
								<img
									src={isMobile ? tab.mobile_image : tab.image}
									alt={'img' + tab.name}
									className={`${isMobile ? 'min-h-[300px] max-[400px]:min-h-[auto]' : ''}`}
								/>
							</div>
						))}
					</div>
				</section>
			</section>
		</>
	)
}

export default Tools
