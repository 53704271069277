import Navbar from "components/Navbar";
import Footer from "components/sections/home/HomeFooter";
import PageHeader from "components/PageHeader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation("home");

  return (
    <main>
      <PageHeader title="Privacy Policy | Smartcomply" />
      <div className="hero">
        <Navbar />
        <div className="flex flex-col w-full bg-white">
          <div className="flex flex-col w-full">
            <div className="mt-[85px] min-h-[450px] max-md:mt-[70px] px-14 pt-14 pb-4 max-md:px-8 max-[520px]:px-4 max-lg:pt-10 relative bg-cover bg-center w-full min-h-[300px] max-md:min-h-[300px] z-[5] relative bg-[linear-gradient(2.3deg,#FFFFFF_44.89%,#E5F0FF_81.6%)]">
              <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row md:justify-between md:space-x-10 py-4 gap-4">
                <div className="w-full space-y-2">
                  <div>
                    <h2 className="font-bold text-[#0c111d] text-[50px]  max-w-[80%] max-lg:text-[40px] max-md:text-[33px] max-md:max-w-[280px] leading-tight text-[#0B1029] max-md:text-center max-md:mx-auto">
                      {t("footer.footerLinks.privacyPolicy")}
                    </h2>
                  </div>

                  <div className="">
                    <p className="text-sm text-[PoppinsMedium] max-md:text-center text-black">
                      {t("blog.header.subtitle")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-[20%] max-lg:px-[10%] max-md:px-8 max-[520px]:px-4 relative bg-cover bg-center w-full min-h-[300px] bg-white text-sm">
            <div className="pb-28">
              <div className="">
                <ul className="font-[Manrope] text-[#36394A] list-style-none list-inside  marker:text-[#0D0D12] marker:text-xl space-y-8">
                  <div className="mb-4">
                    <p className="text-sm font-semibold">
                      Last updated at: 22nd November, 2024
                    </p>
                  </div>
                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Introduction
                    </h3>
                    <div className="text-sm mt-4">
                      This Privacy Notice (&quot;Notice&quot;) governs your use
                      of all our products and services, which includes our
                      websites and Apps listed below (collectively referred to
                      as &quot;Our Products&quot;) and your rights regarding our
                      collection, use, storage and protection of your data. Your
                      privacy is important to us.
                      <br />
                      <span className="font-[600] text-[#0D0D12]">
                        Websites
                      </span>
                      <ul className="list-disc list-inside pl-6 max-md:pl-2">
                        <li>
                          <Link
                            className=" underline  break-all"
                            to="https://secure.smartcomply.com"
                          >
                            https://secure.smartcomply.com
                          </Link>
                        </li>
                        <li>
                          <Link
                            className=" underline  break-all"
                            to="https://intel.smartcomply.com/"
                          >
                            https://intel.smartcomply.com/
                          </Link>
                        </li>
                        <li>
                          <Link
                            className=" underline  break-all"
                            to="https://secure.smartcomply.com/"
                          >
                            https://academy.smartcomply.com/
                          </Link>
                        </li>
                        <li>
                          <Link
                            className=" underline  break-all"
                            to="https://adhere.smartcomply.com/"
                          >
                            https://adhere.smartcomply.com/
                          </Link>
                        </li>
                      </ul>
                      <span className="font-[600] text-[#0D0D12]">Apps</span>
                      <ul className="list-disc list-inside pl-6 max-md:pl-2">
                        <li>
                          <Link
                            className=" underline  break-all"
                            to="https://intel.smartcomply.com/login"
                          >
                            https://intel.smartcomply.com/login
                          </Link>
                        </li>
                        <li>
                          <Link
                            className=" underline  break-all"
                            to="https://app.smartcomply.com/"
                          >
                            https://app.smartcomply.com/
                          </Link>
                        </li>

                        <li>
                          <Link
                            className=" underline  break-all"
                            to="https://adhere-app.smartcomply.com/"
                          >
                            https://adhere-app.smartcomply.com/
                          </Link>
                        </li>
                        <li>
                          <Link
                            className=" underline  break-all"
                            to="https://academy-app.smartcomply.com/"
                          >
                            https://academy-app.smartcomply.com/
                          </Link>
                        </li>
                      </ul>
                      <br />
                      In collecting some information, we are acting as a data
                      controller and processor and, by law, we are required to
                      provide you with information about us, about why and how
                      we use your data, and about the rights you have over your
                      data.
                    </div>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Privacy Notice Overview
                    </h3>
                    <p className="text-sm mt-4">
                      Smartcomply Technology Solutions Limited is dedicated to
                      protecting the privacy of its merchants and users. As
                      such, we have implemented the necessary measures to comply
                      with the Nigeria Data Protection Regulation (NDPR),
                      Nigeria Data Protection Act (NDPA), which is designed to
                      regulate the collection, processing, and use of personal
                      data in Nigeria.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Who are we?
                    </h3>
                    <p className="text-sm mt-4">
                      We are Smartcomply Technology Solutions Limited
                      (hereinafter referred to as &apos;Our Products&apos;). The
                      Bunker Building 279 Herbert Macaulay Way Yaba, Lagos,
                      Nigeria. You can contact us at the above address, by email
                      at{" "}
                      <Link to="mailto:support@smartcomplyapp.com">
                        <span className="font-semibold">
                          support@smartcomplyapp.com
                        </span>{" "}
                      </Link>
                      or by telephone on{" "}
                      <span className="font-semibold"> +234 813 326 2024</span>.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Personal Information Collected
                    </h3>
                    <div className="text-sm mt-4">
                      As a user or merchant using our products, we may collect
                      your personal information including:
                      <ul className="list-disc list-inside">
                        <li>full name</li>
                        <li>email address</li>
                        <li>phone number</li>
                        <li>postal/company address</li>
                        <li>billing and transaction details</li>
                        <li>demographic information</li>
                      </ul>
                      We process your personal information based on the action
                      you take, for example, when you create an account, sign up
                      for newsletters, complete a form related to one of our
                      services, contact us for technical support or customer
                      service, we may also use your information for payment
                      processing purposes, or when it is necessary to comply
                      with applicable laws.
                    </div>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Cookies
                    </h3>
                    <p className="text-sm mt-4">
                      Cookies are tools used to automatically collect
                      information from you when you use Our Products. When you
                      use our website to browse our products and services and
                      view the information we make available, a number of
                      cookies are used by us and by third parties to allow the
                      website to function, to collect useful information about
                      visitors and to help to make your user experience better.
                      <br />
                      Some of the cookies we use are strictly necessary for our
                      website to function, and we do not ask for your consent to
                      place these on your computer and information about the
                      usage is specified in our
                      <Link to="/cookie-notice">Cookie Notice</Link>.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Legal bases
                    </h3>
                    <p className="text-sm mt-4">
                      We are required to process your data under at least one of
                      these lawful bases:
                    </p>
                    <ol className="mt-4 list-[lower-alpha] list-inside marker:text-sm  marker:font-[Manrope] text-[#36394A]">
                      <li className="mb-4">
                        <h3 className="text-sm font-semibold text-left inline">
                          Legitimate interest:{" "}
                          <span className="text-sm font-normal">
                            Processing your data is necessary for our legitimate
                            interests or the legitimate interests of a third
                            party, provided your rights and interests do not
                            outweigh those interests.
                          </span>
                        </h3>
                      </li>

                      <li className="mb-4">
                        <h3 className="text-sm font-semibold text-left inline">
                          Consent:{" "}
                          <span className="text-sm font-normal">
                            You have given explicit consent for us to process
                            your data for a specific purpose.
                          </span>
                        </h3>
                      </li>

                      <li className="mb-4">
                        <h3 className="text-sm font-semibold text-left inline">
                          Legal obligation:{" "}
                          <span className="text-sm font-normal">
                            If the processing of your data is necessary where
                            there is a statutory obligation on us.
                          </span>
                        </h3>
                      </li>
                    </ol>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Purpose of processing your data and the lawful bases
                    </h3>
                    <p className="text-sm mt-4">We collect your data to:</p>
                    <table
                      className="my-2 table-fixed text-[#36394A] border-collapse border [&_td]:border [&_td]:p-2 [&_th]:border [&_th]:p-2"
                      id="privacyNotice"
                    >
                      <thead>
                        <tr>
                          <th>Purpose of Processing</th>
                          <th>Lawful Bases</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>For the administration of our business</td>
                          <td className="!border-0">Legitimate interest</td>
                        </tr>
                        <tr>
                          <td>
                            To help us to develop, improve, customise or
                            restructure our services
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To enforce our terms of service and any terms and
                            conditions of any other agreements for our service
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To send marketing or promotional messages to you
                          </td>
                          <td>Consent</td>
                        </tr>

                        <tr>
                          <td>To take statistical data for use internally</td>
                          <td className="!border-0">Legitimate interest</td>
                        </tr>

                        <tr>
                          <td>To send you service-related messages</td>
                        </tr>

                        <tr>
                          <td>To secure your data and prevent fraud</td>
                          <td>Legitimate interest, legal obligation</td>
                        </tr>
                        <tr>
                          <td>
                            To inform you whenever there are changes to our
                            terms of business or services
                          </td>
                          <td>Legal obligation</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Protection of Personal Information
                    </h3>
                    <p className="text-sm mt-4">
                      Smartcomply is committed to protecting your personal
                      information from unauthorized access, misuse, loss,
                      modification, or disclosure. We have implemented
                      appropriate technical, physical, and administrative
                      safeguards to ensure the confidentiality, integrity, and
                      availability of personal information.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Disclosure of Personal Information
                    </h3>
                    <p className="text-sm mt-4">
                      To protect your privacy is our priority, we limit the
                      sharing of your Personal Information outside of Adhere. We
                      will not share Personal Information about you except under
                      the circumstances listed below. <br />
                      Smartcomply may disclose personal information to its
                      service providers and third-party vendors who work on our
                      behalf. The service providers we use are obligated by
                      contract to keep your personal information private and
                      secure and only use it for its intended purposes in
                      compliance with this Notice. It&apos;s important to note
                      that the data protection laws of the service
                      provider&apos;s country may not be as stringent as those
                      in your country. <br />
                      By agreeing to this Notice, you acknowledge and authorize
                      these third-party service providers to use your personal
                      information. We will take all reasonable precautions to
                      ensure your information is treated with the utmost
                      security and in compliance with the Privacy Notice, and we
                      will not transfer your personal information without
                      appropriate measures being taken. Your information is
                      shared or disclosed with your permission or based on your
                      direction, for instance, if you allow a third-party
                      website client or app to access your account.
                      <br />
                      Smartcomply also reserves the right to disclose your
                      personal information where required by law to regulatory,
                      law enforcement, or other government authorities,
                      including during routine regulatory inspections.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Your Rights
                    </h3>
                    <p className="text-sm mt-4">
                      We will like to ensure that our users and merchants are
                      aware of their rights.
                      <br /> As data subjects, you have the following data
                      protection rights:
                    </p>
                    <ul className="text-sm list-disc list-inside">
                      <li>
                        By law, you can ask us what information we hold about
                        you, and you can ask us to correct it if it is
                        inaccurate.
                      </li>

                      <li>
                        If we have asked for your consent to process your
                        personal data, you may withdraw that consent at any
                        time.
                      </li>
                      <li>
                        If we are processing your personal data for reasons of
                        consent or to fulfil a contract, you can ask us to give
                        you a copy of the information in a machine-readable
                        format so that you can transfer it to another provider.
                      </li>
                      <li>
                        If we are processing your personal data for reasons of
                        consent or legitimate interest, you can request that
                        your data be erased.
                      </li>
                      <li>
                        You have the right to ask us to stop using your
                        information for a period of time if you believe we are
                        not doing so lawfully.
                      </li>
                      <li>
                        You have the right to ask us to erase your personal data
                      </li>
                      <li>
                        Finally, in some circumstances you can ask us not to
                        reach decisions affecting you using automated processing
                        or profiling.
                      </li>
                    </ul>
                    <p className="text-sm mt-4">
                      To exercise these rights or lodge a complaint, please
                      contact Smartcomply&apos;s Data Protection Officer.
                    </p>
                    <p className="text-sm mt-4">
                      The contact details of our Data Protection Officer are:
                    </p>
                    <p className="text-sm mt-4">Name: Opeyemi Ajakaye-Maku</p>
                    <span className="text-sm">
                      Email:{" "}
                      <Link
                        to="mailto:ope@smartcomply.com"
                        className="break-all underline"
                      >
                        ope@smartcomply.com
                      </Link>
                    </span>

                    <p className="text-sm mt-4">
                      Post Address: The Bunker Building 279 Herbert Macaulay
                      Way, Yaba, Lagos, Nigeria.
                    </p>
                    <p className="text-sm mt-4">
                      Phone Number: +234 708 650 1941
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Data retention period
                    </h3>
                    <p className="text-sm mt-4">
                      Your data or any other information collected will be
                      stored for as long as necessary to fulfil the purposes
                      described in this Notice. However, we will also retain
                      data subject to relevant provisions of applicable laws,
                      resolve disputes, and enforce our legal agreements and
                      policies.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Cookie Policy
                    </h3>
                    <p className="text-sm mt-4">
                      Our website and web application currently do not use any
                      cookies or similar tracking technologies to store personal
                      information or track user activity. As we are committed to
                      your privacy and compliance with the Nigeria Data
                      Protection Regulation (NDPR) and Nigeria Data Protection
                      Act (NDPA), we will notify you of any future updates to
                      this policy, including the introduction of cookies. Should
                      we implement cookies in the future, you will be provided
                      with detailed information about the types of cookies,
                      their purposes, and options to manage your cookie
                      preferences. For now, you can browse with confidence that
                      no data is being collected through cookies
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      International transfer of data
                    </h3>
                    <p className="text-sm mt-4">
                      To achieve the purposes described in this Notice, we may
                      transfer your data to countries that may not offer an
                      adequate protection level or are not considered to have
                      sufficient law by the Nigeria Data Protection Regulation
                      (NDPR) and Nigeria Data Protection Act (NDPA).
                      <br />
                      Where personal data is to be transferred to a country
                      outside Nigeria, we shall put adequate measures to ensure
                      data security.
                      <br />
                      Our data transfers to countries that do not offer an
                      adequate protection level are subject to the Nigeria Data
                      Protection Regulation (NDPR) conditions. We will therefore
                      only transfer Personal Data out of Nigeria on one of the
                      following conditions:
                    </p>
                    <ul className="list-disc text-sm list-inside">
                      <li>Your consent has been obtained;</li>

                      <li>
                        The transfer is necessary for the performance of a
                        contract between us and you or implementation of
                        pre-contractual measures taken at your request;
                      </li>
                      <li>
                        The transfer is necessary to conclude a contract between
                        us and the third party in your interest;
                      </li>
                      <li>
                        The transfer is necessary for reason of public interest;
                      </li>
                      <li>
                        The transfer is for the establishment, exercise or
                        defense of legal claims; or
                      </li>
                      <li>
                        The transfer is essential to protect your vital
                        interests or other persons, where the data subject is
                        physically or legally incapable of giving consent
                      </li>
                    </ul>
                    <p className="text-sm mt-4">
                      Please get in touch with us to obtain relevant information
                      regarding your data transfers to third countries
                      (including the appropriate transfer mechanisms).
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Complaints
                    </h3>
                    <p className="text-sm mt-4">
                      If you are concerned about an alleged breach of data
                      protection law or any other regulation by us, you can
                      contact the{" "}
                      <Link
                        className=" underline  break-all"
                        to="mailto:ope@smartcomply.com"
                      >
                        Data Protection Officer (DPO)
                      </Link>
                      . The DPO will investigate your complaint and provide
                      information about how your complaint is handled. <br />{" "}
                      Please be informed that you may complain to the relevant
                      data protection authority where your complaints are not
                      satisfactorily addressed.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Changes to Privacy Notice
                    </h3>
                    <p className="text-sm mt-4">
                      The most current version of this Notice governs our
                      processing of your personal data and we may revise this
                      Notice from time to time as needed. Smartcomply reserves
                      the right to change its Notice as needed for compliance
                      with NDPA & NDPR. Any changes to this Notice will be
                      posted on our website, and the effective date will be
                      indicated.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      {" "}
                      Contact Us
                    </h3>
                    <p className="text-sm mt-4">
                      If you have any questions, concerns, or suggestions
                      regarding our Privacy Notice, please contact our Data
                      Protection Officer. <br />
                      You may further contact us via <br />
                      Phone: +234 813 326 2024 <br />
                      Address: The Bunker Building 279 Herbert Macaulay Way
                      Yaba, Lagos, Nigeria.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default PrivacyPolicy;
